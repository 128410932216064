<template>
  <v-dialog v-model="dialog" scrollable max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">{{ modal_title }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="tableHeaders"
          :items="time_logged"
          disable-pagination
          :hide-default-footer="true"
          no-data-text="No Time Logged found"
        >
          <template v-slot:item.date="{ item }">
            {{ new Date(item.date).toLocaleDateString("en-GB") }}
          </template>
        </v-data-table>
        Total Hours: {{ total }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      tableHeaders: [
        { text: "Date Work Done", value: "date" },
        { text: "Title", value: "title" },
        { text: "Customer", value: "work_chunk.customer.name" },
        { text: "Hours Logged", value: "minutes_to_hours.text" },
        { text: "Date Submitted", value: "formatted_dates.created_at" },
      ],
      time_logged: {},
      modal_title: null,
      total: null,
    };
  },

  methods: {
    open(time_logged = null, modal_title = null, total = 0) {
      this.dialog = true;
      this.time_logged = time_logged;
      this.modal_title = modal_title;
      this.total = total;
    },
  },

  getTimeInHoursAndMins(timeInsSeconds) {
    if (timeInsSeconds && timeInsSeconds > 0) {
      const minsTemp = timeInsSeconds / 60;
      let hours = Math.floor(minsTemp / 60);
      const mins = minsTemp % 60;

      if (hours !== 0 && mins !== 0) {
        if (mins >= 59) {
          hours += 1;
          return +`${hours} ${this.hoursText} `;
        } else {
          return `${hours} ${this.hoursText} ${mins.toFixed(0)} ${
            this.minsText
          }`;
        }
      } else if (hours === 0 && mins !== 0) {
        return `${mins.toFixed(0)} ${this.minsText}`;
      } else if (hours !== 0 && mins === 0) {
        return `${hours} ${this.hoursText}`;
      }
    } else {
      return "-";
    }
  },
};
</script>
